import { TagColors } from '@venncity/venn-ds';
import dayjs from 'dayjs';
import { enumRenewalSurveyAssignmentResponse, enumRiskCategory } from '~/genql';
import { RenewalSurveyAssignmentResponse } from '@venncity/bedrock/lib/genql';

export const leaseEndDateOptions = [
  { value: '90', label: '90-120 Days', start: 90, end: 120 },
  { value: '60', label: '60-90 Days', start: 60, end: 90 },
  { value: '30', label: '30-60 Days', start: 30, end: 60 },
  { value: '21', label: '21-30 Days', start: 21, end: 30 },
  { value: '14', label: '2-3 Weeks', start: 14, end: 21 },
  { value: '7', label: '1-2 Weeks', start: 7, end: 14 },
  { value: '1', label: '1 week or less', start: 0, end: 7 },
];

export const rentRangeOptions = [
  { value: '1', label: '$0 - $1000', start: 0, end: 999 },
  { value: '1000', label: '$1000 - $1500', start: 1000, end: 1499 },
  { value: '1500', label: '$1500 - $2000', start: 1500, end: 1999 },
  { value: '2000', label: '$2000 - $2500', start: 2000, end: 2499 },
  { value: '2500', label: '$2500 - $3000', start: 2500, end: 2999 },
  { value: '3000', label: '$3000 - $3500', start: 3000, end: 3499 },
  { value: '3500', label: '$3500 - $4000', start: 3500, end: 3999 },
  { value: '4000', label: '$4000 - $4500', start: 4000, end: 4499 },
  { value: '4500', label: '$4500 - $5000', start: 4500, end: 4999 },
  { value: '5000', label: 'more than $5000', start: 5000, end: 30000 },
];

type RiskCategory = keyof typeof enumRiskCategory;

export const TagColorMap: Record<RiskCategory, TagColors> = {
  [enumRiskCategory.HIGH]: TagColors.ERROR,
  [enumRiskCategory.MEDIUM]: TagColors.WARNING,
  [enumRiskCategory.LOW]: TagColors.SUCCESS,
};

export const riskStatusLabelMap: Record<RiskCategory, string> = {
  [enumRiskCategory.HIGH]: 'High',
  [enumRiskCategory.MEDIUM]: 'Monitor',
  [enumRiskCategory.LOW]: 'Stable',
} as const;

export const getRentGroup = (rentGroup: number | null | undefined) => {
  for (const rentRange of rentRangeOptions) {
    if (rentGroup && rentGroup >= rentRange.start && rentGroup <= rentRange.end) {
      return rentRange.label;
    }
  }
  return '---';
};

export const getDaysToEndLease = (leaseEndDate: string) => {
  for (const leaseEnd of leaseEndDateOptions) {
    const daysToEndLease = dayjs(leaseEndDate).diff(dayjs(), 'days');

    if (daysToEndLease >= leaseEnd.start && daysToEndLease < leaseEnd.end) {
      return leaseEnd.label;
    }
  }
  return '---';
};

export const formattedResponsesEnum = {
  NOT_TRIGGERED_YET: 'Not triggered',
  NO_RESPONSE: 'No Response',
  WANTS_TO_RENEW: 'Wants to Renew',
  WANTS_TO_MOVE: 'Wants to Move',
  TRANSFER_INTEREST: 'Transfer Interest',
  STILL_DECIDING: 'Still Deciding',
};

export function mapSurveyAssignmentResponse(
  surveyAssignmentTriggered: boolean,
  response?: RenewalSurveyAssignmentResponse | null,
) {
  if (!surveyAssignmentTriggered) {
    return formattedResponsesEnum.NOT_TRIGGERED_YET;
  }
  if (!response) {
    return formattedResponsesEnum.NO_RESPONSE;
  }

  const surveyAssignmentMap = {
    [enumRenewalSurveyAssignmentResponse.YES]: formattedResponsesEnum.WANTS_TO_RENEW,
    [enumRenewalSurveyAssignmentResponse.NO]: formattedResponsesEnum.WANTS_TO_MOVE,
    [enumRenewalSurveyAssignmentResponse.INTERESTED_IN_TRANSFER]:
      formattedResponsesEnum.TRANSFER_INTEREST,
    [enumRenewalSurveyAssignmentResponse.STILL_DECIDING]: formattedResponsesEnum.STILL_DECIDING,
  };
  return surveyAssignmentMap[response as RenewalSurveyAssignmentResponse];
}

export const getSurveyAssignmentColor = (
  surveyAssignmentTriggered: boolean,
  response?: RenewalSurveyAssignmentResponse | null,
) => {
  if (!surveyAssignmentTriggered) {
    return TagColors.ERROR;
  }
  if (!response) {
    return TagColors.WARNING;
  }

  const surveyAssignmentColorMap = {
    [enumRenewalSurveyAssignmentResponse.YES]: TagColors.SUCCESS,
    [enumRenewalSurveyAssignmentResponse.NO]: TagColors.DEFAULT,
    [enumRenewalSurveyAssignmentResponse.INTERESTED_IN_TRANSFER]: TagColors.WARNING,
    [enumRenewalSurveyAssignmentResponse.STILL_DECIDING]: TagColors.PROCESSING,
  };
  return surveyAssignmentColorMap[response as RenewalSurveyAssignmentResponse];
};
